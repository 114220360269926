<template>
  <div class="panel-header panel-header-md" :class="{'warning': computedReturns}">
    <div class="container-fluid">
      <div class="form-horizontal">
      <div class="row">
        <div class="col-md-4 text-white pos">
          <div class="row">
            <label class="col-md-3 col-form-label">會員編號</label>
            <div class="col-md-8 content">
              <!-- <input type="text" readonly class="form-control" :value="data.memberID"> -->
              <div class="row">
              <div class="col-md-6"><input type="text" readonly class="form-control" :value="data.memberID"></div>
              <div class="col-md-6"><input type="text" readonly class="form-control" :value="renderMemberType(data.type)"></div>
              </div>
            </div>
            
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">會員名稱</label>
            <div class="col-md-8 content">
              <input type="text" readonly class="form-control" :value="data.name">
            </div>
          </div>
        </div>
        
        <div class="col-md-4 text-white pos">
          <div class="row">
            <label class="col-md-3 col-form-label">最近回診日期</label>
            <div class="col-md-8 content">
              <input type="text" readonly class="form-control" :value="(data.consultation || []).lastDate">
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">會員生日</label>
            <div class="col-md-8 content">
              <input type="text" readonly class="form-control" :value="data.birthday">
            </div>
          </div>
        </div>
        <div class="col-md-4 text-white pos">
          <div class="row">
            <label class="col-md-3 col-form-label">健保卡證號</label>
            <div class="col-md-8 content">
              <input type="text" readonly class="form-control" :value="data.healthCareID">
            </div>
          </div>
          <div class="row">
            <label class="col-md-3 col-form-label">備註資訊</label>
            <div class="col-md-8 content">
              <input type="text" readonly class="form-control" :value="data.note">
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'overview-header',
  components: {},
  data() {
    return {
      data: {
        name: '',
        memberID: '',
        birthday: '',
        consultation: {}
      },
      value: '',
      staff: [],
      closeOrderStaff: ''
    }
  },
  methods: {
    async init() {
      let localStorageData = JSON.parse(localStorage.getItem('chengyoData'))
      if (localStorageData && Object.values(_.get(localStorageData, 'member', {})).length > 0) {
        this.data = localStorageData.member
      }
    },
    renderMemberType(data) {
      switch (_.get(data, 'value', '')) {
        case 'normalPrice':
          return '一般會員'
        case 'staffPrice':
          return '員工'
        case 'vipPrice':
          return 'VIP'
        case 'friendPrice':
          return '親屬'
        default:
          return '一般會員'
      }
    }
  },
  computed: {
    computedReturns () {
      return this.$router.history.current.fullPath.indexOf('/returns') > -1
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped></style>
