<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">帳號權限管理</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="permissionData">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="80" prop="name" label="帳號">
            </el-table-column>
            <el-table-column min-width="80" prop="fullName" label="使用人全名">
            </el-table-column>
            <el-table-column min-width="10" v-for="col in paramKeyCols" :key="col.key" :label="col.label">
              <template slot-scope="scope">
                <div class="checkbox-cell">
                  <n-checkbox v-model="scope.row.paramKey[col.key]"></n-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn btn-btn btn-default btn-round mx-2" @click.prevent="handleAction('exit')">取消返回</button>
        <button type="button" class="btn btn-btn btn-primary btn-round" @click.prevent="updateAuthData">更新權限</button>
      </div>
    </card>
    <card class="card-plain" card-body-classes="table-full-width bg-white">
      <div slot="header">
        <h4 class="card-title">修改記錄</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table  
          header-cell-class-name="table-transparent"
          header-row-class-name="table-transparent"
          row-class-name="table-transparent"
          :data="historyData"
          class="fixedH265">
            <el-table-column min-width="150" prop="datetime" label="修改日期"> </el-table-column>
            <el-table-column min-width="150" prop="user" label="修改人"> </el-table-column>
            <el-table-column min-width="400" prop="action" label="修改行為"> </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui'
import { Checkbox } from 'src/components'
import API from 'src/api/index.js'
import { getLocalStorage } from 'src/util/index.js'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox
  },
  data() {
    return {
      paramKey: ['member', 'product', 'productEdit', 'order' ,'closeOrder' ,'reportHistory' ,'reportDaily' ,'reportSales' ,'reportVoucher' ,'reportCoupon' ,'staff' ,'auth'],
      paramKeyCols: [
        {
          key: 'member',
          label: '會員列表'
        },
        {
          key: 'product',
          label: '商品列表'
        },
        {
          key: 'productEdit',
          label: '商品修改'
        },
        {
          key: 'order',
          label: '當日訂單'
        },
        {
          key: 'closeOrder',
          label: '關帳紀錄'
        },
        {
          key: 'reportHistory',
          label: '歷史報表'
        },
        {
          key: 'reportDaily',
          label: '每日銷售明細'
        },
        {
          key: 'reportSales',
          label: '銷售員銷售分析'
        },
        {
          key: 'reportVoucher',
          label: '回收券明細'
        },
        {
          key: 'reportCoupon',
          label: '免掛券明細'
        },
        {
          key: 'staff',
          label: '銷售人員管理'
        },
        {
          key: 'auth',
          label: '登入帳號管理'
        }
      ],
      auth: {},
      permissionData: [],
      historyData: []
    }
  },
  methods: {
    async init () {
      let userResult = await API.getUser()
      let authResult = await API.getAuthority()
      if (authResult.message === 'success') {
        this.historyData = _.map(authResult.data, r => {
          r.datetime = moment(r.updateTime, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.behavior = r.action.text
          return r
        })
      }
      if (userResult.message === 'success') {
        this.permissionData = _.map(userResult.data, r => {
          r.paramKey = r.authority
          return r
        })
      }
      let localStorageData = getLocalStorage('chengyoData')
      if (_.get(localStorageData, 'auth', {})) {
        this.auth = localStorageData.auth
      }
    },
    validFormdata () {
      // 檢查登入帳號不可將「登入帳號管理權限」取消
      let isModifyAuth = _.find(this.permissionData, {name: this.auth.name})
      if (isModifyAuth && !isModifyAuth.authority.auth) {
         Swal.fire({
          title: `您不能取消該帳號(${this.auth.name})的「登入帳號管理權限」設定`,
          icon: 'error'
        })
        return false
      }
      // 檢查「登入帳號管理權限」至少需要設定一個使用者
      let authArr = []
      _.map(this.permissionData, r => {
        _.find(r.authority, (o, index) => {
          if (index === 'auth' && o === true) {
            authArr.push(true)
          }
        })
      })
      if (authArr.length === 0) {
        Swal.fire({
          title: '「登入帳號管理權限」至少需要設定一個使用者',
          icon: 'error'
        })
        return false
      }
      return true
    },
    async updateAuthData () {
      let valid = this.validFormdata()
      if (valid) {
        let data = _.map(this.permissionData, r => {
          return {
            id: r.id,
            authority: r.authority
          }
        })
        let resultArr = []
        for (let index in data) {
          let id = data[index].id
          delete data[index].id
          let result = await API.updateAuthority(JSON.stringify(data[index]), id)
          if (result.message === 'success') {
            resultArr.push('success')
          } else {
            resultArr.push('error')
            Swal.fire({
              title: '更新權限錯誤，請再試一次！',
              icon: 'error'
            })
          }
        }
        if (_.indexOf(resultArr, 'error') === -1) {
          Swal.fire({
            title: '更新權限成功，重新登入後權限將生效！'
          })
        }
        this.init()
      }
    },
    handleAction (type) {
      if (type === 'exit') {
        this.$router.push('/accountlist')
      }
    },
    renderAuthDescription (paramKey) {
      let authText = ''
      _.forEach(paramKey, value => {
        switch (value) {
          case 'auth':
            return authText += ' 登入帳號管理 /'
          case 'closeOrder':
            return authText += ' 關帳紀錄 /'
          case 'member':
            return authText += ' 會員列表 /'
          case 'order':
            return authText += ' 當日訂單 /'
          case 'product':
            return authText += ' 商品列表 /'
          case 'productEdit':
            return authText += ' 商品修改 /'
          case 'reportVoucher':
            return authText += ' 回收券明細 /'
          case 'reportDaily':
            return authText += ' 每日銷售明細 /'
          case 'reportHistory':
            return authText += ' 歷史報表 /'
          case 'reportSales':
            return authText += ' 銷售員銷售分析 /'
          case 'reportCoupon':
            return authText += ' 免掛券明細 /'
          case 'staff':
            return authText += ' 銷售人員管理 /'
        }
      })
      return authText
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-transparent {
  background-color: transparent !important;
}
</style>